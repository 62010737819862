var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sub-page',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('ws-accordion',{staticClass:"mt-5",attrs:{"items":_vm.navigationSelect},scopedSlots:_vm._u([{key:"item.stats",fn:function(){return [_c('ws-data-table',{attrs:{"items":_vm.statsItems,"headers":_vm.headers,"no-header":"","no-footer":""},scopedSlots:_vm._u([{key:"item.key",fn:function(ref){
var item = ref.item;
return [_c('h5',{staticClass:"text-no-wrap",style:(("color : " + _vm.wsACCENT))},[_vm._v(_vm._s(item.key))])]}}])})]},proxy:true},{key:"item.businesses",fn:function(){return [_c('ws-data-table',{attrs:{"items":_vm.entity.businesses,"headers":_vm.businessHeaders},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('h5',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.MONTH_DAY_TIME(item.date , false)))])]}},{key:"item.key",fn:function(ref){
var item = ref.item;
return [_c('h5',{staticClass:"text-no-wrap",style:(("color : " + _vm.wsACCENT))},[_vm._v(_vm._s(item.key))])]}},{key:"item.payments",fn:function(ref){
var item = ref.item;
return [_c('h5',{staticClass:"text-no-wrap",style:(("color : " + _vm.wsACCENT))},[_vm._v(_vm._s(item.payed_amount)+"/"+_vm._s(item.confirmed_amount)+" ($)")])]}}])})]},proxy:true}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }